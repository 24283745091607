import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/CO2WsyoD9w4">
    <SEO title="Conflict in Marriage - Marriage, Money, and Minions" />
  </Layout>
)

export default SermonPost
